<template>
  <div
    :tabindex="isDisabled || isActive ? -1 : 0"
    class="base-label"
    v-class-mod:base-label="[
      `radius-${options.radius}`,
      `spacing-${options.spacing}`,
      `type-${labelType}`,
      {
        isActive,
        isDisabled,
        disableDisabledOpacity,
        allowAutoHeight,
        hasError: errors?.length,
      },
    ]"
  >
    <fieldset class="base-label__frame">
      <legend
        v-if="!!legend && (labelType === 'legend' || (labelType === 'frame' && hasValue))"
        class="base-label__legend"
      >
        {{ legend }}
      </legend>
      <div class="base-label__input">
        <icon-wrapper
          v-if="icon"
          :icon="typeof icon === 'object' ? icon.name : icon"
          :color="typeof icon === 'object' ? icon.color : 'black-50'"
        />
        <div class="base-label__slot">
          <slot />
        </div>
        <icon-wrapper
          v-if="errors?.length"
          :tooltip="{ explanation: errors.join(', ') }"
          icon="warning"
          size="medium"
          color="yellow-vivid"
          class="base-label__warning"
        />
      </div>
      <loader-bars v-if="isLoading" />
    </fieldset>

    <input-feedback v-if="feedback" v-bind="feedback" class="base-label__feedback" />
  </div>
</template>
<script setup lang="ts">
import { computed, defineAsyncComponent } from "vue";

import { IconName } from "@horizon56/fonts/types";
import { ColorName, InputSpacingSize, RadiusSize } from "@horizon56/styles/types";

import InputFeedback, { FeedbackProps } from "@/components/inputs/input-feedback.vue";
import LoaderBars from "@/components/loaders/loader-bars.vue";

const IconWrapper = defineAsyncComponent(() => import("@/components/icons/icon-wrapper.vue"));

export type Type = "grid-field" | "legend" | "frame";

export type Styling = {
  radius?: RadiusSize | null;
  spacing?: InputSpacingSize;
};

export type PublicProps = {
  labelType?: Type;
  errors?: string[];
  isLoading?: boolean;
  isDisabled?: boolean;
  disableDisabledOpacity?: boolean;
  allowAutoHeight?: boolean;
  feedback?: FeedbackProps;
  icon?: IconName | { name: IconName; color: ColorName };
};

const props = withDefaults(
  defineProps<
    PublicProps & {
      legend: string;
      hasValue: boolean;
      isActive?: boolean;
    }
  >(),
  { labelType: "frame" },
);

const config: { [key in Type]: Styling } = {
  frame: { radius: "medium", spacing: "medium" },
  "grid-field": { radius: null, spacing: "small" },
  legend: { radius: "medium", spacing: "medium" },
};

const options = computed(() => config[props.labelType]);
</script>

<style lang="scss" scoped>
.base-label {
  $block: &;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  --default-border-width: 1px;
  --disabled-opacity: 0.7;
  --border-width: var(--default-border-width);
  --input-min-height: var(--app-button-height-medium);
  outline: none !important;

  &--type-grid-field {
    --border-width: 0px;
  }
  &--type-legend:not(#{&}--isDisabled) &__frame,
  &--type-grid-field#{&}--isActive &__frame,
  &--type-frame:not(#{&}--isDisabled) &__frame {
    --background: var(--card-bg);
  }

  &__frame {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    background: var(--background);
    border-top-left-radius: var(--label-radius-top-left);
    border-bottom-left-radius: var(--label-radius-bottom-left);
    border-top-right-radius: var(--label-radius-top-right);
    border-bottom-right-radius: var(--label-radius-bottom-right);
    border: var(--border-width) solid var(--border-color, var(--black-20));
    padding: calc(var(--app-input-active-border-width) - var(--border-width)) calc(var(--spacing) - var(--border-width));
    height: var(--app-input-label-height);
  }
  &:has(#{&}__legend) #{&}__frame {
    // height: calc(var(--app-input-label-height) + (var(--app-font-size-label) * 0.5) - 0.5px);
    // padding-top: calc(var(--app-input-spacing-small) + (var(--app-font-size-label) * 0.5));
  }
  &:has(#{&}__legend) #{&}__input {
    // --input-offset: calc(var(--app-font-size-label) * 0.5);
  }
  &__input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    column-gap: 10px;
    margin-top: calc(
      ((var(--app-input-label-height) - var(--input-min-height)) / 2) - var(--app-input-active-border-width)
    );
    height: var(--input-min-height);
  }
  &__slot {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    &:not(:last-child) {
      margin-right: auto;
    }
  }
  &__feedback {
    margin-top: auto;
    margin-left: var(--app-spacing-size-small);
  }
  &__legend {
    line-height: 1;
    padding: 0 2px;
    font-size: var(--app-font-size-label);
    height: 0px;
    transform: translateY(calc(var(--app-font-size-label) * -0.5));
  }
  @each $size in $radiusSizes {
    &--radius-#{$size} {
      --label-radius-top-left: var(--app-radius-#{$size});
      --label-radius-bottom-left: var(--app-radius-#{$size});
      --label-radius-top-right: var(--app-radius-#{$size});
      --label-radius-bottom-right: var(--app-radius-#{$size});
    }
  }
  @each $size in $inputSpacingSize {
    &--spacing-#{$size} {
      --spacing: var(--app-input-spacing-#{$size});
    }
  }
  &:not(#{&}--isActive):not(#{&}--isDisabled) {
    &:focus-visible,
    &:focus {
      --border-width: 2px;
      --border-color: var(--app-focus-color);
    }
  }
  &--isActive {
    --border-width: var(--app-input-active-border-width);
    --border-color: var(--green-vivid);
    &:focus-visible,
    &:focus #{$block}__frame:after {
      outline: none;
    }
  }
  &--isDisabled {
    --border-color: transparent;
    #{$block}__frame {
      opacity: var(--disabled-opacity);
    }
  }
  &--disableDisabledOpacity {
    --disabled-opacity: 1;
  }
  &:not(#{&}--isActive):not(#{&}--isDisabled)#{&}--hasError {
    --border-width: var(--app-input-active-border-width);
    --border-color: var(--yellow-vivid);
  }
  &:not(#{&}--isDisabled):hover {
    cursor: pointer;
    --background: var(--black-10);
  }

  &--allowAutoHeight & {
    &__frame {
      height: unset;
      min-height: var(--app-input-label-height);
    }
    &__input {
      height: unset;
      min-height: var(--input-min-height);
    }
  }
}
</style>
